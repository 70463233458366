import React from 'react';
import { Text } from '@chakra-ui/react';

const ServiceUsage = () => {
  return (
    <>
      <Text as='h1'>
        <strong>마케팅 정보 수신 안내</strong>
      </Text>
      <Text>
        (주)브로우컴퍼니(이하 &#39;회사&#39;)가 운영하는 노블리 서비스(이하 ‘서비스&#39;)를 이용함에 있어, 각종
        경품행사, 이벤트 등의 광고성 정보를 전자우편이나 서신우편, 문자(SMS 또는 카카오톡 메시지), 앱푸시, 전화 등을
        통해 이용자에게 제공하고자 합니다.
        <br />
        귀하는 마케팅 수신동의를 거부하실 수 있으며, 이후라도 본인의 의사에 따라 동의를 철회할 수 있습니다. 동의를
        거부하시더라도 회사가 제공하는 서비스의 이용에 제한이 되지 않습니다.
        <br />
        단, 할인, 이벤트 등의 마케팅 정보 안내 서비스가 제한됩니다.
        <br />
        아래 수집하는 정보는 오직 마케팅 정보 안내 서비스를 위해서만 이용되며 다른 목적으로 활용되거나 제 3자에게
        공유되지 않습니다.
      </Text>
      <br />
      <br />
      <Text as='h3'>
        <strong>수집목적</strong>
      </Text>
      <Text>광고성 정보의 제공</Text>
      <br />
      <br />
      <Text as='h3'>
        <strong>수집항목</strong>
      </Text>
      <Text>성명, ID, 생년월일, 이메일 주소, 서비스 이용기록, 월경 관련 정보</Text>
      <br />
      <br />
      <Text as='h3'>
        <strong>보유기간</strong>
      </Text>
      <Text>회원 탈퇴 시점에 폐기하되, 관계 법령의 규정에 따라 보존할 의무가 있으면 해당기간 동안 보존</Text>
      <br />
      <br />
      <Text as='h3'>
        <strong>부칙</strong>
      </Text>
      <Text>이 약관은 2023년 07월 22일부터 시행합니다.</Text>
    </>
  );
};

export default ServiceUsage;
