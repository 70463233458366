import React from 'react';

const PrivacyPolicyOrderPayment = () => {
  return (
    <>
      <h1 id=''>
        <strong>개인정보처리방침[주문 및 결제]</strong>
      </h1>

      <p>
        (주)브로우컴퍼니는 주문결제를 위하여 다음과 같은 개인정보를 수집하고자 합니다.
        <br />
        <br />
        <br />
      </p>

      <h3 id='-1'>
        <strong>수집목적</strong>
      </h3>

      <h4 id='-2'>주문</h4>

      <p>주문시 결제 및 계약의 이행, 재화의 배송, 원활한 고객상담 및 고충처리</p>

      <h4 id='-3'>결제</h4>

      <p>
        결제 서비스 등록, 주문 및 결제 서비스의 이행
        <br />
        <br />
        <br />
      </p>

      <h3 id='-4'>
        <strong>수집항목</strong>
      </h3>

      <h4 id='-5'>주문</h4>

      <p>
        주문자 정보(이름, 휴대전화번호) 수취인 정보(이름, 휴대전화번호, 수취인 주소) 상품구매 정보(주문번호,
        카드결제정보)
      </p>

      <h4 id='-6'>결제</h4>

      <p>카드정보(카드번호, 유효기간, 비밀번호 앞2자리, 생년월일)</p>

      <h3 id='-7'>
        <strong>보유기간</strong>
      </h3>

      <p>
        회원 탈퇴 시점에 폐기하되, 관계 법령의 규정에 따라 보존할 의무가 있으면 해당기간 동안 보존
        <br />
        <br />
        <br />
      </p>

      <h3 id='-8'>
        <strong>부칙</strong>
      </h3>

      <p>
        이 약관은 2024년 07월 01일부터 시행합니다.
        <br />
        <br />
        <br />
      </p>
    </>
  );
};

export default PrivacyPolicyOrderPayment;
