import React from 'react';
import { VStack, Text, Link as ChakraLink, Image, HStack, useMediaQuery } from '@chakra-ui/react';

const HomeHeader = () => {
  const [isMobile] = useMediaQuery('(max-width: 1200px)');

  return (
    <VStack mt={isMobile ? '100px' : '168px'} mx='24px' gap='0'>
      <Text
        fontSize={isMobile ? '19px' : '20px'}
        fontWeight={500}
        lineHeight='28px'
        letterSpacing='-0.5px'
        textAlign='center'
        color='#262E36'
        mb='8px'
      >
        &apos;불편함의 당연함&apos;이 없어지는 그날까지 노블리가 함께 할게요
      </Text>
      <Text
        as='h1'
        fontSize={isMobile ? '40px' : '48px'}
        fontWeight={900}
        lineHeight='56px'
        letterSpacing='-1.2px'
        textAlign='center'
        color='#191F24'
      >
        월경케어 본질에 집중합니다
      </Text>
      <Image
        src='/logo.png'
        alt='nobly'
        height={isMobile ? '54px' : '80px'}
        mt={isMobile ? '37px' : '44px'}
        mb={isMobile ? '87px' : '44px'}
      />
      <HStack justifyContent='center' wrap='wrap' gap='24px'>
        <ChakraLink
          href='https://apps.apple.com/kr/app/%EB%85%B8%EB%B8%94%EB%A6%AC-%EC%83%9D%EB%A6%AC%EC%A3%BC%EA%B8%B0-%EC%83%9D%EB%A6%AC%EB%8B%AC%EB%A0%A5-%EA%B0%80%EC%9E%84%EA%B8%B0-%EB%B0%B0%EB%9E%80%EC%9D%BC/id6451451153'
          isExternal
        >
          <Image src='/app_store.svg' alt='App Store' maxW='224px' />
        </ChakraLink>
        <ChakraLink href='https://play.google.com/store/apps/details?id=com.nobly.app' isExternal>
          <Image src='/play_store.svg' alt='Play Store' maxW='224px' />
        </ChakraLink>
      </HStack>
    </VStack>
  );
};

export default HomeHeader;
