import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { PolicyType } from 'type/policy-type';
import styled from '@emotion/styled';
import { Link, Text } from '@chakra-ui/react';
import ServiceUsage from './ServiceUsage';
import PrivacyPolicy from './PrivacyPolicy';
import SensitiveInformation from './SensitiveInformation';
import MarketingInformation from './MarketingInformation';
import PrivacyPolicyOrderPayment from './PrivacyPolicyOrderPayment';

interface PolicyBody {
  type: PolicyType;
  body: ReactElement;
}

// 바디 매핑
const policyBodies: PolicyBody[] = [
  {
    type: 'service-usage',
    body: <ServiceUsage />,
  },
  {
    type: 'privacy-policy',
    body: <PrivacyPolicy />,
  },
  {
    type: 'sensitive-information',
    body: <SensitiveInformation />,
  },
  {
    type: 'marketing-information',
    body: <MarketingInformation />,
  },
  {
    type: 'privacy-policy-order-payment',
    body: <PrivacyPolicyOrderPayment />,
  },
];

const Policies = () => {
  const { type } = useParams<{ type: string }>();

  return (
    <Layout>
      {policyBodies.find((value) => value.type === type)?.body}
      <br />
      <br />
      <Text>(주)브로우컴퍼니</Text>
      <Text>대표이사: 고승완</Text>
      <Text>사업자등록번호: 529-87-03339</Text>
      <Text>통신판매업 신고 번호: 2023-용인기능-3333</Text>
      <Text>주소: 경기도 용인시 기흥구 강남서로 9, 7층703호 -B713</Text>
      <Text>
        문의: <Link href='mailto:support@nobly.im'>support@nobly.im</Link>
      </Text>
      <Text>ⓒ Braw company. All Rights Reserved</Text>
      <br />
      <br />
    </Layout>
  );
};

const Layout = styled.main`
  padding: 20px;
  min-height: 100vh;
  overflow: hidden;
  overflow: auto;
  background-color: #161616;
  color: #fff;

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.6rem;
  }

  h3 {
    font-size: 1.4rem;
    line-height: 2;
  }

  h4 {
    font-size: 1.2rem;
    line-height: 2;
  }

  li {
    line-height: 1.5;
  }
`;

export default Policies;
