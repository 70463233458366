import React from 'react';
import { VStack } from '@chakra-ui/react';
import HomeBody from 'components/home/HomeBody';
import { HomeHeader } from '../components/home';
import HomeFooter from '../components/home/HomeFooter';

const Home = () => {
  return (
    <VStack bgColor='#FAF7F9;'>
      <HomeHeader />
      <HomeBody />
      <HomeFooter />
    </VStack>
  );
};

export default Home;
