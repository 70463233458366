import React from 'react';
import { Box, Grid, GridItem, Image, VStack, useMediaQuery } from '@chakra-ui/react';
import HomeGridCard from './HomeGridCard';

const HomeGrid = () => {
  const [isMobile] = useMediaQuery('(max-width: 1200px)');

  const item1 = (
    <Box w='100%' display='flex' justifyContent='flex-end' alignItems='flex-end'>
      <Image src={isMobile ? 'grid_mobile_1.png' : '/grid_1.png'} alt='' h={isMobile ? '240px' : '480px'} />
    </Box>
  );
  const item2 = (
    <HomeGridCard
      src='/grid_icon_1.png'
      title='정확한 예측은 기본'
      subTitle={`계획한 여행이나 약속!\n이제 더이상 미루지 마세요.`}
      content='노블리만의 월경 알고리즘으로 다음 월경일, 가임기, 배란일, 임신확률을 예측해드려요.'
      footer='월경일 기록이 쌓일수록 정교한 예측이 가능해요.'
    />
  );
  const item3 = (
    <HomeGridCard
      src='/grid_icon_2.png'
      title='돌려돌려 월경주기 다이얼'
      subTitle={`지금까지 써 왔던\n평범한 월경관리 앱은 그만!`}
      content='노블리만의 특별한 월경주기 다이얼로 차원이 다른 경험을 느껴보세요.'
      footer='그 어떤 앱보다 사용하기 쉽고 편리해요.'
    />
  );
  const item4 = (
    <HomeGridCard
      src='/grid_icon_3.png'
      title='쉽고 간편하게'
      subTitle={`슬라이드 한번으로\n월경 캘린더를 펼쳐보세요.`}
      content='직관적인 디자인으로 한눈에 월경과 관련된 모든 정보를 파악할 수 있어요.'
      footer='월경 캘린더를 내리면 한 줄의 미니 캘린더로 변해요.'
    />
  );
  const item5 = (
    <HomeGridCard
      src='/grid_icon_4.png'
      title='깜빡 잊어도 괜찮아요'
      subTitle={`월경일, 가임기, 배란일이 오기전에 알림으로\n꼼꼼히 체크해 드릴게요.`}
      content='알림메시지를 내맘대로 수정해서 나만의 시크릿 알림을 만들어보세요.'
      footer='1일~7일까지, 최대 일주일전부터 알림이 가능해요.'
    />
  );
  const item6 = (
    <Box w='100%' display='flex' justifyContent='flex-end' alignItems='flex-end'>
      <Image src={isMobile ? '/grid_mobile_2.png' : '/grid_2.png'} alt='' h='480px' />
    </Box>
  );
  const item7 = <div />;
  const item8 = (
    <HomeGridCard
      src='/grid_icon_5.png'
      title='내 몸은 내가 제일 잘 알아요'
      subTitle='꾸준한 몸상태 기록으로 나의 건강상태를 더 잘 이해할 수 있어요.'
      content='생리양, 기분, 증상, 분비물, 성관계 등 내 몸이 어땠는지 귀여운 아이콘으로 기억해보세요.'
      footer='몸상태를 기록한 날은 캘린더에 노란 점으로 표시하고 있어요.'
    />
  );
  const item9 = (
    <HomeGridCard
      src='/grid_icon_6.png'
      title='더하기보단 빼기에 집중'
      subTitle='인앱 광고, 유료 앱 결제, 홍보성 컬럼 등의 불필요한 콘텐츠 불편하셨죠?'
      content='이젠 헤매지 마세요.'
      footer='노블리는 월경 관리에 꼭 필요한 기능만을 제공할께요.'
    />
  );

  const gridItems = [item1, item2, item3, item4, item5, item6, item7, item8, item9];

  return isMobile ? (
    <VStack mx='24px' mb={0} gap='18px'>
      {item1}
      {item2}
      {item3}
      {item4}
      {item5}
      {item8}
      {item9}
      {item6}
    </VStack>
  ) : (
    <Grid templateColumns='repeat(3, 1fr)' templateRows='repeat(3, 1fr)' gap='24px' mb='100px'>
      {gridItems.map((item) => (
        <GridItem>{item}</GridItem>
      ))}
    </Grid>
  );
};

export default HomeGrid;
