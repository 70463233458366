import React from 'react';
import { Text } from '@chakra-ui/react';

const ServiceUsage = () => {
  return (
    <>
      <Text as='h1'>
        <strong>민감정보 수집 및 이용 동의</strong>
      </Text>
      <Text>
        (주)브로우컴퍼니(이하 &#39;회사&#39;)가 운영하는 노블리 서비스(이하 ‘서비스&#39;)를 이용함에 있어, 개인정보
        보호법 제23조, 제24조의 2에 따라 민감정보를 처리하기 위하여 회사는 정보주체인 귀하의 동의가 필요합니다.
        <br />
        이하 수집하는 정보는 제3자에게 제공되지 않으며, 서비스의 기본기능인 월경 예측 알고리즘 예측 및 앱 내 추천
        알고리즘을 위하여 활용됩니다. 다만, 개인정보 업무처리 위탁이 이뤄질 수 있으며, 이 경우에도 개인을 특정할 수
        없도록 별도의 정보처리를 하는 등 귀하의 민감정보가 유출되지 않도록 최선을 다해 노력합니다.
        <br />
        귀하는 위 민감정보 처리에 동의를 거부할 권리가 있으며, 다만, 위 정보는 월경 예측 알고리즘 등에 필수적인
        정보이므로 정보처리를 거부할 경우 서비스의 이용이 제한됩니다.
      </Text>
      <br />
      <br />
      <Text as='h3'>
        <strong>수집목적</strong>
      </Text>
      <Text>월경 예측 서비스 및 앱 내 추천 알고리즘 활용</Text>
      <br />
      <br />
      <Text as='h3'>
        <strong>수집항목</strong>
      </Text>
      <Text>
        가. 월경에 관한 정보: 월경일자, 출혈량
        <br />
        나. 몸상태: 기분, 증상, 분비물
        <br />
        다. 성생활: 피임여부
      </Text>
      <br />
      <br />
      <Text as='h3'>
        <strong>보유기간</strong>
      </Text>
      <Text>회원 탈퇴 시점에 폐기하되, 관계 법령의 규정에 따라 보존할 의무가 있으면 해당기간 동안 보존</Text>
      <br />
      <br />
      <br />
      <Text as='h3'>
        <strong>부칙</strong>
      </Text>
      <Text>이 약관은 2023년 07월 22일부터 시행합니다.</Text>
    </>
  );
};

export default ServiceUsage;
