import { ListItem, OrderedList, UnorderedList, Text } from '@chakra-ui/react';
import React from 'react';

const PrivacyPolicy = () => {
  return (
    <>
      <Text as='h1'>
        <strong>개인정보처리방침</strong>
      </Text>
      <Text>
        (주)브로우컴퍼니(이하 &#39;회사&#39;)가 운영하는 노블리 서비스(이하 &#39;서비스&#39;)는 이용자의 개인정보 보호를
        중요시합니다. 회사는 이용자가 서비스를 이용함과 동시에 온라인상에서 회사에게 제공한 개인정 보가 보호받을 수
        있도록 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 있으며, 개인정보 처리방침을 개정하는 경우
        앱 내 공지사항(또는 개별공지)을 통하여 공지할 것입니다.
        <br />
        <br />본 개인정보 처리방침은 회사가 제공하는 제반 서비스 이용에 적용 되며 다음과 같은 내용을 담고 있습니다.
      </Text>
      <OrderedList margin={0}>
        <ListItem>개인정보의 수집 및 처리 목적</ListItem>
        <ListItem>개인정보 수집항목 및 수집방법</ListItem>
        <ListItem>개인정보 수집 동의</ListItem>
        <ListItem>개인정보의 보유 및 이용기간</ListItem>
        <ListItem>개인정보의 파기절차 및 방법</ListItem>
        <ListItem>개인정보의 처리 위탁</ListItem>
        <ListItem>이용자 및 법정대리인의 권리와 그 행사방법</ListItem>
        <ListItem>개인정보의 안전성 확보 조치</ListItem>
        <ListItem>개인정보 보호책임자의 연락처</ListItem>
        <ListItem>개인정보 열람청구</ListItem>
        <ListItem>기타</ListItem>
        <ListItem>개인정보 처리방침 변경 고지의 의무</ListItem>
      </OrderedList>
      <br />
      <br />
      <br />
      <Text as='h3'>
        <strong>1. 개인정보의 수집 및 처리 목적</strong>
      </Text>
      <Text>
        회사가 이용자의 개인의 정보를 수집하는 목적은 이용자에게 최적의 서비스를 제공하기 위함으로, 회사는 개인정보를
        다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경될
        시에는 사전동의를 구할 것입니다.
      </Text>
      <br />
      <Text>
        가. 회원가입 및 관리 회원가입 의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원 자격 유지·관리, 서비스
        부정 이용 방지, 각종 민원처리 및 고지·통지 등을 목적으로 개인정보를 처리합니다.
      </Text>
      <br />
      <Text>
        나. 서비스 제공 인구통계학적 특성에 따른 서비스 제공, 기능 개선, 접속 빈도 파악, 콘텐츠 제공, 웹뷰 내 연동 유지,
        자료분석 등을 목적으로 개인정보를 처리합니다.
      </Text>
      <br />
      <Text>
        다. 마케팅 및 광고에의 활용 앱푸시, SMS, 광고 전송, 이벤트 정보 및 참여기회 제공, 서비스의 유효성 확인 등을
        목적으로 개인정보를 처리합니다.
      </Text>
      <br />
      <Text>
        라. 서비스 개선 목적 연구 개발 서비스 기능 개선, 기술 개발 및 신규 서비스 출시 등을 목적으로 개인정보를
        처리합니다.
      </Text>
      <br />
      <br />
      <br />
      <h3>
        <strong>2. 개인정보 수집항목 및 수집방법</strong>
      </h3>
      <Text>
        회사는 서비스 제공을 원활하게 하기 위해 필요한 최소한의 정보만을 수집하고 있으며, 이후 사용자의 편의를 위한 부가
        서비스 제공을 위해 기타 정보를 추가로 수집할 수 있습니다. 다만, 민감정보의 경우 이용자로부터 별도의 동의를
        얻으며, 회사가 수집하는 개인정보의 항목 및 방법은 아래와 같습니다.
      </Text>
      <br />
      <Text>가. 개인정보 수집항목</Text>
      <br />
      <UnorderedList styleType='none' margin={0}>
        <ListItem>
          - 회원가입 시
          <br />
          필수항목: 소셜로그인플랫폼(네이버, 카카오, 구글, 애플)의 개인식별용 키값
          <br />
          선택항목: 닉네임
        </ListItem>
        <ListItem>
          - 서비스 이용 중
          <br />: 서비스 이용 기록, 월경 관련 정보, 접속로그, 접속 IP정보, 단말기 정보(단말기 모델, OS유형, 이동통신사
          정보, 하드웨어 ID, 광고ID, 서비스 이용에 대한 기본 통계), 애플리케이션 설치 및 사용 이력
        </ListItem>
        <ListItem>
          - 구매계약 시
          <br />: 주문자 및 수령인의 성명, 주문자 또는 수령인의 휴대전화(전화번호), 수령인의 배송지 주소, 이메일주소,
          계좌은행, 예금주명
        </ListItem>
        <ListItem>
          - 이벤트
          <br />
          회원이 이벤트에 응모하는 경우 해당 이벤트에 응모하기로 한 이용자에 한하여 다음과 같은 정보들이 수집될 수
          있습니다.
          <br />: 이름, 주소, 핸드폰 번호, ID, 이메일 주소
        </ListItem>
      </UnorderedList>
      <br />
      <Text>나. 개인정보 수집방법</Text>
      <br />
      <UnorderedList styleType='none' margin={0}>
        <ListItem>
          - 이용자가 직접 입력하는 경우
          <br />: 회원 가입 시, 서비스 이용 중, 회원 정보 수정 등을 통해 직접 입력된 개인정보가 수집될 수 있습니다.
        </ListItem>
        <ListItem>
          - 상담 등을 통해 수집되는 경우
          <br />: 고객센터를 통한 상담 과정에서 앱, 웹페이지, 전자우편, 팩스, 전화, 카카오톡 등을 통해 이용자의
          개인정보가 수집될 수 있습니다.
        </ListItem>
        <ListItem>
          - 서비스 이용 중 자동 생성 및 수집되는 경우
          <br />: 서비스 이용 기록, 접속 로그, 접속 IP정보, 단말기 정보, 애플리케이션 설치 및 사용 이력 등의 정보가
          모바일 웹/앱 이용 과정에서 자동으로 생성되어 수집될 수 있습니다.
        </ListItem>
        <ListItem>
          - 건강 관련 앱으로부터 수집되는 경우
          <br />: 이용자가 접근 권한을 허용할 경우, 모바일 기기 내 설치된 건강 관련 앱(애플 건강 등)으로부터 월경 주기
          기록 등이 수집될 수 있습니다.
        </ListItem>
      </UnorderedList>
      <br />
      <br />
      <br />
      <Text as='h3'>
        <strong>3. 개인정보 수집 동의</strong>
      </Text>
      <Text>
        회사는 이용자의 개인정보 수집에 대하여 동의를 받고 있으며, 회사 내의 회원가입 절차 중 이용약관 및
        개인정보처리방침에 개인정보 수집 동의절차를 마련해 두고 있습니다. 이용자께서 이용약관 동의란에 체크하시면
        개인정보 수집에 대해 동의한 것으로 간주합니다.
      </Text>
      <br />
      <br />
      <Text as='h3'>
        <strong>4. 개인정보 보유 및 이용기간</strong>
      </Text>
      <Text>
        회사는 회원가입일로부터 회원탈퇴와 같은 개인정보 수집, 이용, 제공에 대한 동의 철회 시까지 이용자의 개인정보를
        보유 및 이용하게 됩니다. 회원탈퇴가 완료되면 이용자의 개인정보를 파기하는 등 필요한 조치를 취합니다.
      </Text>
      <br />
      <br />
      단, 다음과 같은 이유에 해당될 때에는 명시한 기간 동안 보존합니다.
      <br />
      <Text>가. 회사의 정책에 의한 개인정보의 보존</Text>
      <br />
      <UnorderedList styleType='none' margin={0}>
        <ListItem>
          부정 가입 및 징계기록 등의 부정이용기록
          <br />
          보존 이유: 부정 가입 및 이용 방지
          <br />
          보존 기간: 수집 시점으로부터 1년
        </ListItem>
      </UnorderedList>
      <br />
      <Text>나. 관계법령에 의한 개인정보의 보존</Text>
      <br />
      <Text>
        관계 법령 규정에 따라 보존이 필요한 경우에 법령에서 규정한 일정한 기간 동안 이용자의 개인정보를 보관하며, 법령에
        따른 내용은 아래와 같습니다.
      </Text>
      <br />
      <UnorderedList styleType='none' margin={0}>
        <ListItem>
          - 접속에 관한 기록
          <br />
          보존 이유: 통신비밀보호법 제15조의2 및 시행령 제41조
          <br />
          보존 기간: 3개월
          <br />
        </ListItem>
        <ListItem>
          - 표시/광고에 관한 기록
          <br />
          보존 이유: 전자상거래등에서의 소비자보호에 관한 법률
          <br />
          보존 기간: 6개월
          <br />
        </ListItem>
        <ListItem>
          - 계약 또는 청약철회 등에 관한 기록
          <br />
          보존 이유: 전자상거래등에서의 소비자보호에 관한 법률
          <br />
          보존 기간: 5년
          <br />
        </ListItem>
        <ListItem>
          - 대금결제 및 재화 등의 공급에 관한 기록
          <br />
          보존 이유: 전자상거래등에서의 소비자보호에 관한 법률
          <br />
          보존 기간: 5년
          <br />
        </ListItem>
        <ListItem>
          - 소비자의 불만 또는 분쟁처리에 관한 기록
          <br />
          보존 이유: 전자상거래등에서의 소비자보호에 관한 법률
          <br />
          보존 기간: 3년
        </ListItem>
      </UnorderedList>
      <br />
      <br />
      <br />
      <Text as='h3'>
        <strong>5. 개인정보의 파기절차 및 방법</strong>
      </Text>
      <Text>
        회사는 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및
        방법은 다음과 같습니다.
      </Text>
      <br />
      <Text>가. 파기절차</Text>
      <Text>
        이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에
        따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른
        목적으로 이용되지 않습니다.
      </Text>
      <br />
      <Text>나. 파기방법</Text>
      <Text>
        전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 파기합니다. 종이에 출력된 개인정보는
        분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
        <br />
        <br />
        <br />
      </Text>
      <Text as='h3' id='-6-'>
        <strong>6. 이용자 및 법정대리인의 권리와 그 행사방법</strong>
      </Text>
      <br />
      <Text>
        가. 이용자 및 법정대리인은 언제든지 등록되어 있는 자신의 개인정보를 열람(조회)하거나 정정(수정)할 수 있으며,
        가입해지(회원탈퇴)를 요청할 수 있습니다.
      </Text>
      <br />
      <Text>
        나. 이용자 및 법정대리인의 개인정보 열람, 정정, 가입해지는 개인정보보호 책임자에게 서면, 전화 또는 전자우편 등을
        통해 연락 하시면 지체 없이 조치하겠습니다.
      </Text>
      <br />
      <Text>
        다. 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는
        제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이
        통지하여 정정이 이루어지도록 하겠습니다.
      </Text>
      <br />
      <Text>
        라. 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 &quot;4. 개인정보 보유 및
        이용기간&quot;에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리합니다.
      </Text>
      <br />
      <Text>
        마. 이용자는 자신의 개인정보를 최신의 상태로 유지해야 하며, 이용자의 부정확한 정보 입력으로 발생하는 문제의
        책임은 이용자 자신에게 있습니다. 또한 이용자는 휴대폰 번호, 전자우편, 비밀번호 등에 대한 보안을 유지할 책임이
        있으며 제3자에게 이를 양도하거나 대여할 수 없습니다.
      </Text>
      <br />
      <Text>
        바. 타인의 개인정보를 도용한 회원가입의 경우 이용자 자격을 상실하거나 관련 개인정보보호 법령에 의해 처벌받을 수
        있습니다.
      </Text>
      <br />
      <br />
      <br />
      <Text as='h3'>
        <strong>7. 개인정보의 안전성 확보 조치</strong>
      </Text>
      <Text>
        회사는 개인정보보호법 제29조에 따라 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는
        훼손되지 않도록 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.
      </Text>
      <br />
      <Text>
        가. 개인정보 취급 직원의 최소화 및 교육 개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여
        개인정보를 관리하는 대책을 시행하고 있습니다.
      </Text>
      <br />
      <Text>
        나. 내부관리계획의 수립 및 시행 개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.
      </Text>
      <br />
      <Text>
        다. 개인정보의 암호화 이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며
        중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고
        있습니다.
      </Text>
      <br />
      <Text>
        라. 개인정보에 대한 접근 제한 개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여
        개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을
        통제하고 있습니다.
      </Text>
      <br />
      <br />
      <br />
      <Text as='h3'>
        <strong>8. 행태정보의 수집·이용·제공 및 거부 등에 관한 사항</strong>
      </Text>
      <Text>
        회사는 서비스 이용과정에서 정보주체에게 최적화된 맞춤형 서비스 및 혜택, 온라인 맞춤형 광고 등을 제공하기 위하여
        행태정보를 수집·이용하고 있습니다.
        <br />
        <br />
        회사는 다음과 같이 행태정보를 수집합니다.
      </Text>
      <UnorderedList styleType='none' margin={0}>
        <ListItem>
          - 수집하는 행태정보의 항목: 이용자의 앱 서비스 방문이력, 기타 이용자의 앱 사용 관련 온라인상 활동정보
        </ListItem>
        <ListItem>
          - 행태정보 수집방법: 이용자의 앱/웹 방문/실행 시 자동 수집, Firebase, Meta, Datadog, Amplitude 등 SDK의 설치를
          통한 이용자의 행태정보 수집, 제휴사로부터 제휴사가 직접 수집한 행태정보를 제공받거나, 제휴사로부터 제휴사
          사이트 내 행태정보 수집을 허용받아 수집 툴 등을 통해 자동 수집 전송, 기타 관련 법령 및 가이드에 따라 허용된
          방법
        </ListItem>
        <ListItem>
          - 행태정보 수집목적: 이용자 행태정보 분석하여 신상품 개발, 서비스 개편 등 척도로 활용, 최척화 된 개인 맞춤형
          상품 추천 서비스(광고 포함) 제공
        </ListItem>
        <ListItem>- 행태정보 보유/이용기간: 수집일로부터 2년</ListItem>
        <ListItem>
          - 이용자 통제권 행사 방법: (1) (안드로이드) ① 설정 → ② 개인정보보호 → ③ 광고 → ③ 광고 ID 재설정 또는 광고ID
          삭제, (2) (아이폰) ① 설정 → ② 개인정보보호 → ③ 추적 → ④ 앱이 추적을 요청하도록 허용 끔
        </ListItem>
      </UnorderedList>
      <Text>※ 모바일 OS 버전에 따라 메뉴 및 방법이 다소 상이할 수 있습니다.</Text>
      <br />
      <br />
      <br />
      <Text as='h3'>
        <strong>9. 개인정보 보호책임자</strong>
      </Text>
      <Text>
        회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및
        개인정보보호책임자를 지정하고 있으며, 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
      </Text>
      <br />
      <Text>
        성명: 고승완
        <br />
        직책: 대표이사
        <br />
        연락처: 010-7599-1803
        <br />
        전자우편: support@nobly.im
      </Text>
      <Text>
        정보주체께서는 회사의 서비스를 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한
        사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다/ 회사는 정보주체의 문의에 대해 최대한 성실하게
        답변을 드릴 것입니다.
        <br />
        <br />
        <br />
      </Text>
      <Text as='h3'>
        <strong>10. 개인정보 열람청구</strong>
      </Text>
      <Text>
        아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나
        보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
      </Text>
      <br />
      <UnorderedList styleType='none' margin={0}>
        <ListItem>
          <Text>
            개인정보 침해신고센터(한국인터넷진흥원 운영)
            <br />
            - 소관업무: 개인정보 침해사실 신고, 상담 신청
            <br />
            - 홈페이지: privacy.kisa.or.kr
            <br />- 전화: (국번없이)118
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            개인정보 분쟁조정위원회
            <br />
            - 소관업무: 개인정보 분쟁조정신청, 집단분쟁조정(민사적 해결)
            <br />
            - 홈페이지: kopico.go.kr
            <br />- 전화: 1833-6972
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            대검찰청 사이버범죄수사단
            <br />
            - 홈페이지: www.spo.go.kr
            <br />- 전화: (국번없이)1301
          </Text>
        </ListItem>
        <ListItem>
          경찰청 사이버테러대응센터
          <br />
          - 홈페이지: www.ctrc.go.kr
          <br />- 전화: 02-392-0330
        </ListItem>
      </UnorderedList>
      <br />
      <br />
      <br />
      <Text as='h3'>
        <strong>11. 기타</strong>
      </Text>
      <Text>
        회사가 제공하는 서비스에 링크되어 있는 웹페이지들이 개인정보를 수집하는 행위에 대해서는 본 서비스의
        &#39;개인정보처리방침&#39;이 적용되지 않음을 알려드립니다.
      </Text>
      <br />
      <br />
      <br />
      <Text as='h3'>
        <strong>12. 고지의 의무</strong>
      </Text>
      <Text>
        현 개인정보 처리방침의 내용 추가, 삭제 및 수정이 있을 시에는 시행일자 최소 7일전부터 서비스 내
        &quot;공지사항&quot; 화면을 통해 공고할 것입니다.
      </Text>
      <br />
      <Text>- 개인정보처리방침 시행일자: 2023년 07월 22일</Text>
    </>
  );
};

export default PrivacyPolicy;
