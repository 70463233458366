import React from 'react';

const ServiceUsage = () => {
  return (
    <>
      <h1 id='-'>
        <strong>서비스 이용약관</strong>
      </h1>
      <h3 id='-1-'>
        <strong>제 1장 총칙</strong>
      </h3>
      <h4 id='-1-'>제 1조 (목적)</h4>
      <p>
        이 약관은 (주)브로우컴퍼니(이하 &#39;회사&#39;라고 합니다)가 제공하는 노블리 서비스(이하 &#39;서비스&#39;라고
        합니다)의 이용과 관련하여 회원과 &quot;회사&quot; 간에 필요한 사항을 규정함을 목적으로 합니다.
      </p>
      <h4 id='-2-'>제 2조 (용어의 정의)</h4>
      <p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
      <ol>
        <li>
          &quot;서비스&quot;란 구현되는 단말기(PC, 휴대형 단말기 등의 각종 유무선 장치를 포함)와 상관없이 회원이 이용할
          수 있는 서비스 및 이와 관련된 제반 서비스를 의미합니다.
        </li>
        <li>
          &quot;회원&quot;이라 함은 서비스에 접속하여 이 약관에 따라 회사와 이용계약을 체결하고, 회사가 제공하는
          서비스를 이용하는 고객을 말합니다.
        </li>
        <li>
          &quot;닉네임&quot;이라 함은 회원의 서비스 이용을 위하여 회원이 이용약관 및 개인정보 처리방침에 동의한 후
          수집한 회원의 계정정보에 저장된 &quot;닉네임(이름)&quot;을 말합니다.
        </li>
        <li>
          닉네임, 생년월일 등 회원이 회사에 제공한 정보 및 서비스를 이용하는 과정에서 생성되는 서비스 이용정보, 부호,
          문자, 영상 등으로 표현된 모든 종류의 정보를 의미합니다.
        </li>
        <li>
          &quot;콘텐츠&quot;라 함은 서비스에서 회원에게 제공하는 모든 종류의 문장, 음성, 음악, 이미지, 동영상, 기타정보
          등을 말합니다.
        </li>
        <li>
          이 약관에서 사용하는 용어 중 본 조에서 정하지 아니한 것은 노블리 서비스 안내 및 관계법령에서 정하는 바에
          따르며, 그 외에는 일반 상관례에 따릅니다.
          <br />
          <br />
          <br />
        </li>
      </ol>
      <h3 id='-2-'>
        <strong>제 2장 서비스 이용계약</strong>
      </h3>
      <h4 id='-3-'>제 3조 (약관의 게시와 개정)</h4>
      <ol>
        <li>회사는 이 약관의 내용을 회원이 쉽게 알 수 있도록 서비스 내 또는 연결화면을 통하여 게시합니다.</li>
        <li>회사는 필요한 경우 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</li>
        <li>
          회사가 이 약관을 개정할 경우에는 개정내용과 적용일자를 명시하여 서비스에서 적용일자 7일 전부터 적용일자
          전일까지 공지합니다. 다만, 회원에게 불리한 변경의 경우에는 적용일자 30일 전부터 공지합니다. 이 경우 회사는
          개정 전 내용과 개정 후 내용을 명확하게 비교하여 회원이 알기 쉽도록 표시합니다.
        </li>
        <li>
          회사가 전항에 따라 공지하면서 회원에게 적용일자 전일까지 의사표시를 하지 않으면 의사표시가 표명된 것으로
          본다는 뜻을 명확하게 공지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정약관에
          동의한 것으로 봅니다.
        </li>
        <li>
          회원은 개정 약관에 동의하지 않는 경우 적용일자 전일까지 회사에 거부의사를 표시하고 서비스 이용계약을 해지할 수
          있습니다.
        </li>
      </ol>
      <h4 id='-4-'>제 4조 (약관 외 준칙)</h4>
      <ol>
        <li>
          회사는 제공하는 서비스 내의 개별 서비스에 대한 별도의 약관 및 이용조건을 둘 수 있으며 개별 서비스에서 별도로
          적용되는 약관에 대한 동의는 회원이 개별 서비스를 최초로 이용할 경우 별도의 동의 절차를 거치게 됩니다. 이 경우
          개 별 서비스에 대한 이용 약관 등이 이 약관에 우선합니다.
        </li>
        <li>
          이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하 여는 『전자상거래 등에서의 소비자 보호에 관한 법률],
          &quot;약 관의 규제에 관한 법률」, 공정거래위원회가 정하는 전자상 거래 등에서의 소비자 보호지침 및 관계법령 및
          상관례에 따릅니다.
        </li>
        <li>
          이 약관과 관련하여 회사의 정책 변경, 법령의 제· 개정 또는 공공기관의 고시나 지침 등에 의하여 회사가 서비스 내
          공 지사항이나 홈페이지의 게시판 등을 통해 공지하는 내용도 이용계약의 일부를 구성합니다.
        </li>
      </ol>
      <h4 id='-5-'>제 5조 (회원가입)</h4>
      <ol>
        <li>
          회원가입 신청자가 회사가 정한 가입양식에 따라 회원정보 를 정확히 기입한 후 이 약관에 동의한다는 의사표시를
          함으로써 회원가입을 신청하고, 회사가 회원가입을 승낙함으로써 회원가입이 완료됩니다. 다만, 회사는 이러한 신청에
          있어 회원가입 신청자에게 휴대폰 기기인증, 전문기관을 통 한 실명확인 등 본인인증을 요청할 수 있습니다. 또한,
          14세 미만의 이용자의 경우 회원이 되기 위하여, 별도의 법정대리 인의 동의를 요청할 수 있습니다.
        </li>
        <li>
          회사는 제1항에 따른 회원가입 신청에 대하여 승낙할 수 있습니다. 다만, 회사는 아래의 어느 하나에 해당하는 신청에
          대해서는 회원가입을 승낙하지 않거나 사후에 본 이 용계약을 해지할 수 있습니다.
        </li>
        <li>휴대폰 기기 인증 등 회사가 요청한 인증절차를 완료 하지 않은 경우</li>
        <li>
          회원가입 신청자가 이 약관에 의하여 이전에 회원자 격을 상실한 적이 있는 경우. 단, 회사의 회원 재가입 승낙을
          얻은 경우는 예외로 합니다.
        </li>
        <li>회원가입 신청에 있어서 회원 정보를 허위로 입력한 경우</li>
        <li>회사가 정한 절차에 따라 회사가 제시하는 내용을 기 재하지 않은 경우</li>
        <li>회원가입 신청자의 귀책사유로 인해 승인이 불가능하거나 기타 규정한 제반 사항을 위반하여 신청한 경우</li>
        <li>
          기타 이 약관에 위반되거나 위법 또는 부당한 가입 신 청임이 확인된 경우 또는 회원가입 승낙이 부적합하다 고
          합리적으로 판단되는 경우
        </li>
        <li>
          회사는 서비스 관련 설비의 여유가 없거나 기술상 또는 업 무상 문제가 있는 경우, 기타 회사가 합리적인 판단에
          의하여 필요하다고 인정하는 경우에는 승낙을 유보할 수 있습니다.
        </li>
        <li>
          본 조제2항과 제3항에 따라 회원 가입 신청의 승낙을 하지 아니하거나 유보한 경우 회사는 원칙적으로 이를 회원가입
          신청자에게 알리도록 합니다.
        </li>
        <li>회원가입 계약의 성립시기는 회사의 승낙이 회원가입 신청자에게 도달한 시점으로 합니다.</li>
      </ol>
      <h4 id='-6-'>제 6조 (회원정보의 제공 및 변경)</h4>
      <ol>
        <li>
          회원은 이 약관에 의하여 회사에 정보를 제공하여야 하는 경우에는 진실된 정보를 제공하여야 하며, 허위정보
          제공으로 인해 발생한 불이익에 대해서는 보호받지 못합니다.
        </li>
        <li>
          회원은 개인정보 관리화면을 통하여 본인의 개인정보를 열람하고 수정할 수 있습니다. 회사는 향후 회원의 동의 하에
          개인정보를 추가적으로 수집할 수 있습니다.
        </li>
        <li>
          회원은 회원가입 신청 시 기재하거나 제공한 사항이 잘못되었거나 변경되었을 경우 상당한 기간 이내에 회원정보
          수정, 서비스 내 1:1 문의하기 화면, 이메일, 카카오채널 등의 방법 으로 해당 사항을 회사에 알려야 합니다.
        </li>
        <li>
          회원정보가 잘못되었거나 변경되었음에도 해당 사항을 수 정하지 않거나 해당 사항을 회사에 알리지 않아 발생한 각
          종 손해와 잘못된 수정으로 인하여 발생한 손해는 당해 회원이 부담하여야 합니다.
        </li>
      </ol>
      <h4 id='-7-'>제 7조 (회원 탈퇴 및 자격 상실 등)</h4>
      <ol>
        <li>
          회원은 언제든지 회사가 정하는 절차에 따라 이용계약 해지 (회원 탈퇴)를 요청할 수 있으며, 회사는 관계 법령 등이
          정 하는 바에 따라 이를 즉시 처리합니다. 단, 회원 탈퇴 시 회원으로서의 모든 혜택은 소멸됩니다.
        </li>
        <li>회원이 이용 계약을 해지하는 경우 회사는 개인정보 처리방침에 따라 회원정보를 파기 조치합니다.</li>
        <li>이용 계약이 해지된 경우 회원 계정에 등록된 서비스 이용정보 일체는 삭제됩니다.</li>
        <li>
          회원이 제 14조(회원의 의무)의 규정을 위반한 경우 회사는 사전통보 없이 일방적으로 본 계약을 해지할 수 있고,
          이로 인하여 서비스 운영에 손해가 발생한 경우 이에 대한 민• 형사상의 책임도 물을 수 있습니다. 제8조 (이용의
          제한)
        </li>
        <li>
          회사는 회원이 이 약관의 의무를 위반하거나 서비스의 정상 적인 운영을 방해한 경우, 경고, 일시 정지, 회원자격
          상실 등으로 서비스 이용을 제한할 수 있습니다.
        </li>
        <li>
          회사가 회원자격을 정지시킨 후 동일한 행위가 2회 이상 반 복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우
          회사는 회원자격을 상실시킬 수 있습니다.
        </li>
        <li>
          회사는 전 항에도 불구하고, 『저작권법」 및 『컴퓨터프로그램 보호법」을 위반한 불법프로그램의 제공 및 운영방해,
          「정보 통신망 이용촉진 및 정보보호 등에 관한 법률」을 위반한 불 법통신 및 해킹, 악성프로그램의 배포, 접속권한
          초과행위 등과 같이 관계 법령을 위반한 경우에는 즉시 회원자격을 상실시킬 수 있습니다.
        </li>
        <li>
          본 조에 따라 회사가 회원과의 계약을 해지하는 등 회원의 회원자격을 상실시키고자 하는 경우, 회사는 회원등록을
          말소합니다. 이 경우 회원에게 SMS 등을 통해 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여
          소명할 기회를 부여합니다.
        </li>
        <li>
          회원은 본 조에 따른 이용제한 등에 대해 회사의 고객센터 를 통해 이의신청을 할 수 있습니다. 이 때 이의가
          정당하다 고 회사가 인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.
        </li>
      </ol>
      <h4 id='-8-'>제 8조 (회원에 대한 통지)</h4>
      <ol>
        <li>회사가 회원에 대한 통지를 하는 경우, 회원이 미리 약정하여 지정한 SMS 등으로 할 수 있습니다.</li>
        <li>
          회사는 불특정다수 회원에 대한 통지의 경우 1주일 이상 서비스 내 공지사항 페이지에 게시함으로써 개별 통지에 갈음
          할 수 있습니다. 다만, 회원 본인의 거래에 관련하여 중대한 영향을 미치는 사항에 대하여는 개별 통지합니다.
        </li>
      </ol>
      <h4 id='-9-'>제 9조 (정보의 제공 및 광고의 게재)</h4>
      <ol>
        <li>
          회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사항 또는 전자우편이나 서신우편, SMS, 전화
          등의 방법으로 회원에게 제공할 수 있습니다. 다만, 회원은 관련법에 따른 거래관련정보 및 고객문의 등에 대한 답변
          등을 제외하고는 언제든지 SMS 등에 대해서 수신 거절을 할 수 있습니다.
        </li>
        <li>
          회사는 서비스의 운영과 관련하여 서비스 화면, SMS 등에 광고를 게재할 수 있습니다. 광고가 게재된 SMS 등을 수신
          한 회원은 수신 거절의 의사표시를 할 수 있으며, 회사는 수 신 거절을 위한 방법을 회원에게 제공합니다.
        </li>
      </ol>
      <h4 id='-10-'>제 10조 (개인정보의 보호 및 제공)</h4>
      <ol>
        <li>회사는 회원의 개인정보 수집 시 서비스 제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.</li>
        <li>회사는 회원의 개인정보를 수집•이용하는 때에는 당해 회원에게 그 목적을 고지하고 동의를 받습니다.</li>
        <li>
          회사는 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용 목적이 발생한 경우 또는 제3자에게 제공
          하는 경우에는 이용•제공 단계에서 당해 회원에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리
          정함이 있는 경우에는 예외로 합니다.
        </li>
        <li>
          회사가 제2항에 의해 회원의 동의를 받아야 하는 경우에는 개인정보 관리 책임자의 신원(소속, 성명 및 전화번호,
          기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보 제공 관련사항(제공받은 자, 제공목적 및 제공할
          정보의 내 용) 등 『정보통신망 이용촉진 및 정보보호 등에 관한 법률」 에서 규정한 사항을 미리 명시하거나
          고지해야 하며 회원은 언제든지 이 동의를 철회할 수 있습니다.
        </li>
        <li>
          회원은 언제든지 회사가 가지고 있는 자신의 개인정보에 대 해 열람 및 오류 정정을 요구할 수 있으며 회사는 이에
          대해 지체 없이 필요한 조치를 취할 의무를 집니다. 회원이 오류 의 정정을 요구한 경우에는 회사는 그 오류를 정정할
          때까지 당해 개인정보를 이용하지 않습니다.
        </li>
        <li>
          회사 또는 그로부터 개인정보를 제공받은 제3자는 개인정 보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해
          개인 정보를 지체 없이 파기합니다.
        </li>
        <li>
          회사의 서비스(제2조 제1항에서 정한 범위 내) 이외의 링크 된 사이트에서는 회사의 개인정보 처리방침이 적용되지
          않습니다. 링크된 사이트 및 상품 등을 제공하는 제3자의 개인 정보 취급과 관련하여서는 해당 사이트 및 제3자의
          개인정보 처리방침을 확인할 책임이 회원에게 있으며, 회사는 이에 대하여 책임을 부담하지 않습니다.
        </li>
        <li>
          회사는 회원의 귀책사유로 인하여 노출된 회원의 개인정보 를 포함한 모든 정보에 대해서 일체의 책임을 지지
          않습니다.
        </li>
        <li>
          회사는 서비스 운영과 제공 목적 외에는 회원의 개인정보를 제 3자에게 제공하지 않음을 기본 원칙으로 합니다. 다만
          약관 의무 위반, 서비스 정상 운영 방해, 기타 관련 법률 위반 행위로 인해 불가피하게 요구되는 경우에는 법이
          허용하는 범위 내에서 제 3자에게 개인정보를 제공할 수 있습니다. 이용자가 서비스 이용을 위해 선택적으로 입력하는
          월경일자 등 민감정보는 개인정보와 별개로 분류하며 어떠한 경우에도 제 3자에게 제공하지 않습니다.
        </li>
        <li>
          전항에 불구하고 개인정보보호법 제 3절 가명정보처리에 관한 특례에 의거하여, 통계작성, 과학적 연구, 공익적
          기록보존 등을 위하여 필요한 경우 특정 개인을 식별할 수 없는 형태(가명정보)로 제공할 수 있습니다.
          <br />
          <br />
          <br />
        </li>
      </ol>
      <h3 id='-3-'>
        <strong>제 3장 회사와 회원의 의무</strong>
      </h3>
      <h4 id='-11-'>제 11조 (회사의 의무)</h4>
      <ol>
        <li>
          회사는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고,
          안정적으로 서비스를 제공하는 데 최선을 다하여야 합니다.
        </li>
        <li>
          회사는 회원이 안전하게 서비스를 이용할 수 있도록 회원의 개인정보(신용정보 포함)보호를 위한 보안시스템을 갖추어
          야 하며, 개인정보 처리방침을 공시하고 준수합니다.
        </li>
        <li>
          회사는 서비스 이용과 관련하여 회원으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는
          적절한 절차를 거쳐 즉시 처리하여야 합니다. 다만, 즉시 처리 가 곤란한 경우에는 회원에게 그 사유와 처리일정을
          통보하여야 합니다.
        </li>
      </ol>
      <h4 id='-12-id-'>제 12조 (회원의 ID 및 비밀번호에 대한 의무)</h4>
      <ol>
        <li>
          ID와 비밀번호에 관한 관리책임은 회원에게 있으며, 이를 소홀히 하여 발생한 모든 민, 형사상의 책임은 회원
          자신에게 있습니다.
        </li>
        <li>회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.</li>
        <li>
          회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 즉시 회사에 통보하고
          회사의 안내가 있는 경우에는 그에 따라야 합니다.
        </li>
        <li>
          회원이 제3항에 따른 통지를 하지 않거나 회사의 조치에 응하지 아니하여 발생하는 모든 불이익에 대한 책임은
          회원에게 있습니다.
        </li>
        <li>
          스마트기기 및 회원정보에 관한 관리책임은 회원에게 있습니다. 회원이 본인의 회원정보를 소홀히 관리하거나, 제3자
          에게 이용을 승낙함으로써 발생하는 손해에 대하여는 회원에게 책임이 있습니다.
        </li>
      </ol>
      <h4 id='-13-'>제 13조 (회원의 의무)</h4>
      <ol>
        <li>
          회원은 관련법령, 이 약관의 규정, 이용안내 등 회사가 통지 하는 사항을 준수하여야 하며, 기타 회사 업무에
          방해되는 행위를 하여서는 안됩니다.
        </li>
        <li>회원은 아래의 행위를 하여서는 안 됩니다.</li>
        <li>신청 또는 변경 시 허위 내용의 등록</li>
        <li>타인의 정보 도용 </li>
        <li>사이트에 게시된 정보의 변경</li>
        <li>회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시</li>
        <li>회사 기타 제3자의 저작권 등 지식재산권에 대한 침해</li>
        <li>회사 기타 제3자의 명예를 손상시키거나 업무를 방해 하는 행위</li>
        <li>회사의 동의 없이 영리를 목적으로 서비스를 사용하는 행위</li>
        <li>
          회사의 서비스에 게시된 정보를 회사의 사전 승낙 없이 영리 또는 비영리 목적으로 복제, 출판, 방송 등에 사용하는
          행위
        </li>
        <li>
          회사가 제공하는 서비스를 이용하여 제3자에게 본인을 홍보할 기회를 제공하거나 제3자의 홍보를 대행하는 등의
          방법으로 금전을 수수하거나 서비스를 이용할 권리를 양도하고 이를 대가로 금전을 수수하는 행위
        </li>
        <li>
          회사의 직원이나 서비스의 관리자를 가장하거나 사칭하여 또는 타인의 명의를 도용하여 글을 게시하거나 메일을
          발송하는 행위
        </li>
        <li>다른 회원의 개인정보를 동의 없이 수집, 저장, 공개 하는 행위</li>
        <li>
          불특정 다수의 회원을 대상으로 하여 광고 또는 선전을 게시할 목적으로 회사에서 제공하는 서비스를 이용하여
          영리활동을 하는 행위
        </li>
        <li>회사가 제공하는 소프트웨어 등을 개작하거나 리버스 엔지니어링, 디컴파일, 디스어셈블 하는 행위</li>
        <li>현행 법령, 회사가 제공하는 서비스에 정한 약관 기타 서비스 이용에 관한 규정을 위반하는 행위</li>
        <li>기타 불법적이거나 부당한 행위</li>
        <li>
          회원은 회사가 제공하는 서비스를 이 약관, 운영정책 및 회사가 설정한 규칙에 따라 이용할 수 있습니다. 회원은 관계
          법령, 이 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야
          하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안됩니다.
        </li>
        <li>
          회사는 회원이 제2항의 행위를 하는 경우 서비스의 이용을 제한하거나 일방적으로 본 계약을 해지할 수 있습니다.
        </li>
        <li>
          회사가 제공하는 서비스 중 관계 법령 등의 규정에 의하여 성인 인증이 필요한 경우 회원은 해당 서비스를 이용하기
          위하여 회사가 제공하는 방법에 따라 실명 정보를 회사에 제공하여야 합니다.
        </li>
      </ol>
      <h4 id='-14-'>제 14조 (서비스의 제공 및 변경)</h4>
      <ol>
        <li>회사는 회원에게 아래와 같은 서비스를 제공합니다.</li>
        <li>월경 주기 기록 서비스 제공</li>
        <li>월경 주기, 월경 기간, 관련 증상 및 증상 발생일 등 여성 건강과 관련된 일정 기록 저장 서비스 제공</li>
        <li>피임 / 월경 / 임신 / 출산 등과 관련된 여성 건강 지식 정보 제공</li>
        <li>전자상거래를 통한 여성용품 등 제품 구매 서비스</li>
        <li>기타 회사가 회원에게 제공하는 일체의 서비스</li>
        <li>
          회사는 모든 회원에게 1일 24시간 서비스 제공을 원칙으로 합니다. 단, 관계 법령 및 회사 정책에 따라 회원 별로
          서비스 이용의 범위, 방법, 내용, 제공시간, 횟수 등이 제한될 수 있습니다.
        </li>
        <li>
          회사는 서비스 제공에 필요할 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스 화면에 공지한 바에
          따릅니다.
        </li>
      </ol>
      <h4 id='-15-'>제 15조 (서비스의 중단)</h4>
      <ol>
        <li>
          회사는 컴퓨터 등 정보통신설비의 보수점검 • 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의
          제공을 일시적으로 중단할 수 있습니다.
        </li>
        <li>
          회사는 제1항의 사유로 서비스가 중단된 경우 즉시 이러한 사실을 공지하되, 만일 정보통신설비의 작동불능 등의 불가
          피한 사유로 인해 사전공지가 불가능한 경우에는 이러한 사정이 해소된 이후 즉시 공지합니다.
        </li>
        <li>
          회사는 본 조제1항의 사유가 발생한 경우 최대한 빠른 시간 내에 서비스를 재개하도록 최선의 노력을 다합니다.
        </li>
        <li>
          회사는 파산 또는 회생절차 개시 등 정상적인 영업활동이 곤란하여 서비스를 지속할 수 없는 때에는 회원에게 이를
          통지 한 날로부터 30일이 경과한 때부터 서비스를 중단할 수 있습니다.
        </li>
        <li>
          회사는 운영상, 경영상, 기술상의 필요에 따라 제공하고 있는 서비스의 전부 또는 일부 내용을 변경하거나 서비스를
          종료 할 수 있습니다.
        </li>
        <li>
          서비스의 내용, 이용방법 등에 대하여 변경이 있는 경우에는 변경사유, 변경될 서비스의 내용 및 제공일자 등을 변경
          7일 전에, 서비스를 종료하는 경우에는 30일 전에 서비스 화면에 공지합니다. 다만, 사전에 공지할 수 없는 부득이한
          사정 이 있는 경우에는 사후에 지체 없이 이를 공지할 수 있습니다.
        </li>
        <li>
          회사는 본 조에 따른 서비스의 중단, 변경 또는 종료로 인하여 발생한 손해에 대하여 어떠한 책임도 부담하지
          않습니다.
        </li>
      </ol>
      <h4 id='-16-'>제 16조 (권리의 귀속 및 이용제한)</h4>
      <ol>
        <li>
          서비스에 대한 저작권 및 지적재산권은 회사에 귀속합니다. 회사가 작성한 저작물에 대한 저작권 및 기타 지식재산권
          역시 회사에 귀속합니다.
        </li>
        <li>
          회사는 서비스와 관련하여 회원에게 회사가 정한 이용조건에 따라 계정, ID, 콘텐츠 등을 이용할 수 있는 이용권만을
          부여 하며, 회원은 서비스를 이용함으로써 얻은 정보 중 회사에게 지식재산권이 귀속된 정보를 회사의 사전 승낙 없이
          복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리 목적 으로 이용하거나 제3자에게 이용하게 하여서는
          안됩니다.
        </li>
      </ol>
      <h4 id='-17-'>제 17조 (면책)</h4>
      <ol>
        <li>
          회사는 아래의 사유로 서비스를 제공할 수 없는 경우 이로 인해 회원에게 발생한 손해에 대해서는 책임을 부담하지
          않습니다.
        </li>
        <li>천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우</li>
        <li>회원의 귀책사유로 서비스 이용에 장애가 있는 경우</li>
        <li>기타 회사의 고의 또는 과실이 없는 사유로 인한 경우</li>
        <li>
          서비스와 관련한 회원정보에 대한 관리 • 보존 책임은 전적으로 회원에게 있고, 회사는 회원의 정보 및 자료를 백업 •
          복원 • 반환 해야 할 의무가 없습니다. 이를 위해 회원은 회원의 필요에 따라 자체적으로 독립적인 별도 저장소에
          회원 정보를 보관해야 합니다.
        </li>
        <li>
          회사는 회원 상호간 또는 회원과 제3자 상호 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며, 이로
          인한 손해를 배상할 책임을 지지 않습니다.
        </li>
        <li>
          회사는 무료로 제공되는 서비스 이용과 관련하여 관계 법령에 특별한 규정이 없는 한 책임을 지지 않습니다.
          <br />
          <br />
          <br />
        </li>
      </ol>
      <h3 id='-4-'>
        <strong>제 4장 구매계약 및 대금결제</strong>
      </h3>
      <h4 id='-18-'>제 18조 (구매의 신청)</h4>
      <ol>
        <li>
          이용자는 서비스에서 다음 또는 이와 유사한 방법에 의하여, 법에 의거해 구매를 신청하며, 회사는 이용자가
          구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.
        </li>
        <li>1) 재화 등의 검색 및 선택</li>
        <li>2) (주문자, 수취인)성명, 주소, 휴대전화번호 등의 입력</li>
        <li>3) 약관내용, 청약 철회권이 제한되는 서비스, 배송료 등의 비용부담과 관련한 내용에 대한 확인</li>
        <li>4) 재화 등의 구매신청 및 이에 관한 확인 또는 회사의 확인에 대한 동의</li>
        <li>5) 결제 방법의 선택</li>
        <li>
          제3자에게 회원 또는 이용자의 개인정보를 제공할 필요가 있는 경우 실제 구매 신청 시 회원 또는 이용자의 동의를
          받습니다. 이때 회사는 제공되는 개인정보 항목, 제공 받는 자, 제공받는 자의 개인정보 이용 목적 및 보유, 이용
          기간 등을 회원에게 명시하여야 합니다.
        </li>
      </ol>
      <h4 id='-19-'>제 19조 (구매의 성립)</h4>
      <ol>
        <li>회사는 제 18조 구매의 신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다.</li>
        <li>1) 신청 내용에 허위, 기재누락, 오기가 있는 경우</li>
        <li>2) 미성년자가 담배, 주류 등 청소년보호법에서 금지하는 상품 및 용역을 구매하는 경우</li>
        <li>3) 구매신청을 승낙하는 것이 회사 기술상 현저히 지장이 있다고 판단하는 경우</li>
        <li>4) 기타 제반 법령 및 정부의 가이드라인에 위반되는 경우</li>
        <li>
          회사의 승낙이 제 8조의 통지형태로 이용자에게 도달한 시점에 구매계약이 성립한 것으로 봅니다. 회사의 승낙의사
          표시에는 이용자의 구매신청에 대한 확인 및 판매가능 여부, 구매신청의 정정 취소 등에 관한 정보 등을 포함하여야
          합니다.
        </li>
      </ol>
      <h4 id='-20-'>제 20조 (지급방법)</h4>
      <ol>
        <li>
          서비스에서 구매한 상품 또는 용역에 대한 대금지급방법은 다음 각 호의 방법 중 가용한 방법으로 할 수 있습니다.
          단, 회사는 이용자의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여 징수할 수 없습니다.
        </li>
        <li>1) 폰뱅킹, 인터넷뱅킹 등의 각종 계좌이체</li>
        <li>2) 선불카드, 직불카드, 신용카드 등의 각종 카드 결제</li>
        <li>3) 카카오페이, 네이버페이 등의 각종 간편 결제</li>
        <li>4) 온라인 무통장입금 등</li>
        <li>
          회사가 지급한 쿠폰에 의한 혹은 결제회사가 지급한 적립금에 의한 결제 구매대금의 결제와 관련하여 이용자가 입력한
          정보 및 그와 관련된 책임은 이용자에게 있으며, 재화 또는 용역의 청약 후 합리적인 일정 기간 내에 결제가
          이루어지지 않는 경우 회사는 이에 해당주문을 취소할 수 있습니다.
        </li>
        <li>
          회사는 구매자의 결제수단에 대하여 정당한 사용권한 보유여부를 확인할 수 있으며 필요한 경우 해당 거래진행의 정지
          및 소명자료의 제출을 요청할 수 있습니다.
        </li>
        <li>
          회사는 회원 및 이용자의 대금 지급에 법적, 기술적 문제가 발생하거나 회사가 예견하지 못한 장애(은행 통신망 장애
          등)가 발생하는 경우 회사의 정책에 따라 회원에게 결제 수단의 변경을 요청하거나 잠정 결제보류 내지 결제를 거부할
          수 있습니다.
        </li>
        <li>
          회원 및 이용자가 구매대금의 결제와 관련하여 입력한 정보 및 그 정보와 관련하여 발생한 책임과 불이익은 전적으로
          회원이 부담합니다.
        </li>
      </ol>
      <h4 id='-21-'>제 21조 (수신확인통지, 구매신청 변경 및 취소)</h4>
      <p>
        회사는 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다. 수신확인 통지를 받은 이용자는 의사표시의
        불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있고, 회사는 배송 전에
        이용자의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 수신확인 통지를 받은 이용자가 대금을
        지불한 경우에는 제 24조의 청약철회 등에 관한 규정에 따릅니다.
      </p>
      <h4 id='-22-'>제 22조 (재화 등의 공급)</h4>
      <p>
        회사는 이용자의 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상, 이용자가 청약을 한 날로부터 7일 이내에 재화
        등을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다. 이때 회사는 이용자가 재화 등의 공급 절차
        및 진행 사항을 확인 할 수 있도록 적절한 조치를 합니다. 공휴일 및 기타 휴무일 또는 천재지변 등의 불가항력적
        사유가 발생하는 경우 그 해당기한은 배송소요기간에서 제외합니다.
      </p>
      <h4 id='-23-'>제 23조 (환급)</h4>
      <p>
        회사는 이용자가 구매 신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를
        이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 5영업일 이내에 환급하거나 환급에
        필요한 조치를 취합니다.
      </p>
      <h4 id='-24-'>제 24조 (청약 철회 등)</h4>
      <ol>
        <li>
          회사와 재화 등의 구매에 관한 계약을 체결한 이용자는 수신확인의 통지를 받은 날로부터 7일 이내에 청약의 철회를
          할 수 있습니다. 다만, 통지를 받은 때보다 공급이 늦게 이루어진 경우에는 재화 등의 공급을 받은 날로부터 7일
          이내에 철회를 할 수 있습니다. 이용자는 재화 등을 배송 받는 경우 다음 각호에 해당하는 경우에는 반품 및 교환을
          할 수 없습니다.
        </li>
        <li>1) 이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우</li>
        <li>2) 시간의 경과에 의하여 재판매가 곤란할 정도로 재화 등의 가치가 현저히 감소한 경우</li>
        <li>3) 같은 성능을 지닌 재화 등으로 복제가 가능한 경우 그 원본인 재화 등의 포장을 훼손한 경우</li>
        <li>4) 그 밖에 거래의 안전을 위하여 대통령령이 정하는 경우</li>
        <li>
          회사가 사전에 청약 철회 등이 제한되는 사실을 소비자가 쉽게 알 수 있는 곳에 명기하거나 시용 상품을 제공하는
          등의 조치를 하지 않았다면 이용자의 청약 철회 등이 제한되지 않습니다.
        </li>
        <li>
          이용자는 제 1항 및 제 2항의 규정에도 불구하고 재화 등의 내용이 표시, 광고 내용과 다르거나 계약 내용과 다르게
          이행된 때에는 당해 재화 등을 공급받은 날부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에
          이를 회사에 입증하여 청약 철회 등을 할 수 있습니다.
        </li>
      </ol>
      <h4 id='-25-'>제 25조 (청약 철회 등의 효과)</h4>
      <ol>
        <li>
          회사는 이용자로부터 재화 등을 반환 받은 경우 해당 재화 수령시점부터 3영업일 이내에 이미 지급받은 재화 등의
          대금을 환급하도록 노력합니다. 이 경우 회사가 이용자에게 재화 등의 환급을 지연한 때에는 그 지연 기간에 대하여
          공정거래 위원회가 정하여 고시하는 지연 이자율을 곱하여 산정한 지연 이자를 지급합니다.
        </li>
        <li>
          회사는 위 대금을 환급함에 있어서 구매자가 신용카드 또는 전자금융거래법 등이 정하는 결제수단으로 재화 등의
          대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는
          취소하도록 요청할 수 있습니다. 청약 철회 등의 경우 공급 받은 재화 등의 반환에 필요한 비용은 이용자가
          부담합니다.
        </li>
        <li>
          회사는 이용자에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화 등의 내용이 표시
          내용과 다르거나 계약 내용과 다르게 이행되어 청약 철회 등을 하는 경우 재화 등의 반환에 필요한 비용은 회사가
          부담합니다.
        </li>
        <li>단순변심으로 인한 청약 철회의 겨우, 수수료 및 기타 제반비용은 회원 또는 이용자가 부담합니다.</li>
        <li>
          기타 이 약관에 규정되지 않은 취소 및 환불에 대한 사항에 대해서는 소비자 분쟁해결 기준에서 정하는 바에
          따릅니다.
        </li>
      </ol>
      <h4 id='-26-'>제 26조 (배송 및 거래완료)</h4>
      <ol>
        <li>
          회사는 상품의 배송과 관련하여 회원과 배송업체 등 관련 당사자 사이에 분쟁 등이 발생하는 경우에는 당사자의
          요청에 따라 관련된 자료를 제공할 수는 있으나, 이에 관여하지 않으며 어떠한 책임도 부담하지 않습니다.
        </li>
        <li>
          회원은 구매한 상품의 배송이 완료되었을 때로부터 7일 이내에 회사에 대하여 구매확정, 교환 또는 반품의 의사표시를
          하여야 합니다.
        </li>
        <li>
          회사는 제 2항의 구매확정 기간 내에 구매확정, 교환 또는 반품에 대한 회원의 의사표시가 없는 경우, 해당 거래에
          대하여 회원의 구매확정 의사 표시가 있는 것으로 간주하여 자동구매 확정으로 처리할 수 있습니다.
        </li>
        <li>
          회원 및 이용자는 구매확정이 이루어지면, 회사에 대하여 상품의 하자, 미수령, 반품 등의 사유로 이의를 제기할 수
          업습니다.
          <br />
          <br />
          <br />
        </li>
      </ol>
      <h3 id='-5-'>
        <strong>제 5장 정기구독 서비스 및 할인</strong>
      </h3>
      <h4 id='-27-'>제 27조 (정기구독 서비스)</h4>
      <p>
        정기구독 서비스(또는 정기주문 서비스, 월경구독 서비스, 이하 정기구독 서비스)는 고객의 편의를 위하여 상품을
        정기적으로 배송하는 서비스입니다.
      </p>
      <h4 id='-28-'>제 28조 (정기구독 서비스 이용 자격)</h4>
      <p>정기구독 서비스는 회원이면 누구나 정기구독 서비스를 신청함으로써 이용이 가능합니다.</p>
      <h4 id='-29-'>제 29조 (정기구독 서비스 상품의 결제 등)</h4>
      <ol>
        <li>
          회사는 정기구독 서비스 상품에 대하여 할인 혜택을 제공할 수 있으며, 할인율 및 거래조건 등은 상품 별로 다를 수
          있고 변경될 수 있습니다. 할인율 및 거래조건 등이 고객에게 불리하게 변경되는 경우 회사는 7일 이상의 상당한 기간
          동안 위 변경에 대하여 공지하고, 고객은 위 변경에 동의하지 않으면 위 기간 동안 본 서비스를 종료시킬 수 있으며,
          위 기간 동안 서비스를 종료시키지 않으면 위 변경에 고객이 동의한 것으로 봅니다.
        </li>
        <li>정기구독 서비스 상품의 추가 또는 변경 시 결제되는 총 상품의 금액은 변경됩니다.</li>
        <li>
          카드 한도 초과 등으로 상품의 결제가 이루어 지지 않을 경우, 해당 회차의 정기구독은 이루어지지 않을 수 있으며,
          위와 같은 상황이 2회 이상 지속되는 경우 회사는 배송을 연기하거나 정기구독 서비스를 중지할 수 있습니다.
        </li>
        <li>
          고객의 요청과 동의가 있는 경우, 정기구독 서비스의 결제 주기, 배송 상품, 결제 금액, 결제 시기 등이 변경될 수
          있습니다.
        </li>
      </ol>
      <h4 id='-30-'>제 30조 (정기구독 서비스 상품의 판매 종료 등)</h4>
      <p>
        정기구독 대상 상품을 더 이상 판매할 수 없거나 해당 상품을 정기구독 서비스로 제공할 수 없는 사유가 있는 경우,
        회사는 해당 상품의 정기구독 서비스를 중지하거나 해당 회차의 상품을 공급하지 않을 수 있습니다.
      </p>
      <h4 id='-31-'>제 31조 (정기구독 서비스의 종료)</h4>
      <p>고객은 회사에게 통지함으로써 정기구독 서비스를 종료시킬 수 있습니다.</p>
      <h4 id='-32-'>제 32조 (정기구독 서비스 이용의 제한)</h4>
      <ol>
        <li>
          고객의 통지 또는 과실에 의해서 정기구독 서비스가 중지되는 경우(일부 상품에 대하여 정기구독 서비스가 중지되는
          경우를 포함합니다.) 해당 상품의 정기구독 서비스 신청이 2개월의 범위 내에서 제한될 수 있습니다.
        </li>
        <li>회사는 특정 상품에 대하여 구매 수량을 제한할 수 있습니다.</li>
        <li>
          회사는 상품의 재판매 가능성이 있는 경우, 또는 불법적이거나 부당한 행위와 관련된 경우 정기구독 서비스 제공을
          제한할 수 있습니다.
        </li>
        <li>서비스 이용의 제한에 대한 구체적인 기준은 내부 운영정책에 따라 적용됩니다.</li>
        <li>
          회사는 자체적인 시스템을 통한 모니터링과 각종 기관에서 접수된 민원 내용, 수사기관의 정보 등을 통해 정황을
          확인한 후, 정기구독 서비스 제한 사유 행위를 한 것으로 추정되는 경우 정기구독 서비스 이용을 제한할 수 있습니다.
          <br />
          <br />
          <br />
        </li>
      </ol>
      <h3 id='-6-'>
        <strong>제 6장 기타 사항</strong>
      </h3>
      <h4 id='-33-'>제 33조 (분쟁해결)</h4>
      <ol>
        <li>
          회사는 회원으로부터 제출되는 불만사항 및 의견에 대하여 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가
          곤란한 경우에는 회원에게 그 사유와 처리일정을 수일 내로 통보합니다.
        </li>
        <li>
          만약 본 약관의 환불 등 일부 규정이 대한민국의 강행법규와 상충되는 경우에는 그 강행법규에 따르기로 하고, 이로
          인해 본 약관 중 일부 조항의 효력이 정지되는 경우에도 다른 조항의 효력에는 영향을 미치지 아니합니다.
        </li>
      </ol>
      <h4 id='-34-'>제 34조 (재판권 및 준거법)</h4>
      <ol>
        <li>이 약관의 해석 및 회사와 회원간의 분쟁에 대하여는 대한민국의 법률을 적용합니다.</li>
        <li>
          이 약관 및 서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생하여 소송이 제기되는 경우에는 『민사소송법]에
          따라 관할법원을 정합니다.
          <br />
          <br />
          <br />
        </li>
      </ol>
      <h3 id='-'>
        <strong>부칙</strong>
      </h3>
      <p>
        이 약관은 2024년 07월 01일부터 시행합니다.
        <br />
        <br />
        <br />
      </p>
    </>
  );
};

export default ServiceUsage;
