import React from 'react';

const Instagram = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
    >
      <g clipPath='url(#clip0_1929_904)'>
        <path
          d='M22.5 0H7.5C3.3 0 0 3.3 0 7.5V22.5C0 26.7 3.3 30 7.5 30H22.5C26.7 30 30 26.7 30 22.5V7.5C30 3.3 26.7 0 22.5 0ZM27 22.5C27 25.05 25.05 27 22.5 27H7.5C4.95 27 3 25.05 3 22.5V7.5C3 4.95 4.95 3 7.5 3H22.5C25.05 3 27 4.95 27 7.5V22.5Z'
          fill='#9A9A9A'
        />
        <path
          d='M15 7.5C10.8 7.5 7.5 10.8 7.5 15C7.5 19.2 10.8 22.5 15 22.5C19.2 22.5 22.5 19.2 22.5 15C22.5 10.8 19.2 7.5 15 7.5ZM15 19.5C12.45 19.5 10.5 17.55 10.5 15C10.5 12.45 12.45 10.5 15 10.5C17.55 10.5 19.5 12.45 19.5 15C19.5 17.55 17.55 19.5 15 19.5Z'
          fill='#9A9A9A'
        />
        <path
          d='M22.5 9C23.3284 9 24 8.32843 24 7.5C24 6.67157 23.3284 6 22.5 6C21.6716 6 21 6.67157 21 7.5C21 8.32843 21.6716 9 22.5 9Z'
          fill='#9A9A9A'
        />
      </g>
      <defs>
        <clipPath id='clip0_1929_904'>
          <rect width='30' height='30' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Instagram;
