import React from 'react';
import { VStack, Image } from '@chakra-ui/react';
import HomeGrid from './HomeGrid';

const HomeBody = () => {
  return (
    <VStack mx='24px' mt='24px' mb={0} w='100%' maxW='1200px'>
      <Image src='/device.png' alt='' w='100%' maxW='842px' mt='24px' mx='24px' />
      <Image src='/sec_title.svg' alt='' w='300px' marginBottom='48px' />
      <HomeGrid />
    </VStack>
  );
};

export default HomeBody;
