import React from 'react';
import { VStack, Text, HStack, Box, Link as ChakraLink } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Instagram from 'components/icon/Instagram';
import Routes from 'utils/routes';

const HomeFooter = () => {
  return (
    <HStack justifyContent='center' w='100%' py='60px' bgColor='#282828'>
      <HStack w='100%' maxW='1200px' mx='20px' justifyContent='space-between' alignItems='flex-end' wrap='wrap'>
        <VStack alignItems='flex-start' color='#9A9A9A' gap={0}>
          <Text fontSize='18px' color='white'>
            (주) 브로우컴퍼니
          </Text>
          <HStack my='20px'>
            <Link to={Routes.policies('privacy-policy')}>개인정보처리방침</Link>
            <Box w='1px' h='16px' bgColor='#9A9A9A' />
            <Link to={Routes.policies('service-usage')}>서비스 이용약관</Link>
          </HStack>
          <Text>대표이사 : 고승완</Text>
          <Text>사업자등록번호 : 529-87-03339</Text>
          <Text>통신판매업 신고 번호 : 2023-용인기흥-3333</Text>
          <Text>주소 : 경기도 용인시 기흥구 강남서로 9, 7층 703호 -B713</Text>
          <Text>
            문의 : <ChakraLink href='mailto:support@nobly.im'>support@nobly.im</ChakraLink>
          </Text>
          <Text mt='20px'>Copyright 2023. Braw company. All Rights Reserved</Text>
        </VStack>
        <ChakraLink href='https://www.instagram.com/nobly.im/' isExternal>
          <Instagram />
        </ChakraLink>
      </HStack>
    </HStack>
  );
};

export default HomeFooter;
