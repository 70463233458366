import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NotFound from './pages/NotFound';
import Policies from './pages/policies/Policies';
import AppDownload from './pages/AppDownload';
import Home from './pages/Home';

const App = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/policies/:type' element={<Policies />} />
      <Route path='/app/download' element={<AppDownload />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
};

export default App;
