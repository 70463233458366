import { extendTheme } from '@chakra-ui/react';
// `@chakra-ui/theme` is a part of the base install with `@chakra-ui/react`
// import chakraTheme from '@chakra-ui/theme';

const theme = extendTheme({
  styles: {
    global: {
      'html, body': {
        fontFamily: 'Pretendard',
        verticalAlign: 'middle',
      },
      a: {
        textDecoration: 'none',
        color: 'inherit',
        ':hover': {
          textDecoration: 'underline',
        },
      },
      button: {
        border: 'none',
        color: 'inherit',
      },
      'ol li': {
        listStyle: 'decimal',
        listStylePosition: 'inside',
      },
    },
  },
});

export default theme;
