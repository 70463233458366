import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Button, Text } from '@chakra-ui/react';
import { isIos, isMac } from '../utils/user-agent';

const AppDownload = () => {
  // 자동 실행
  useEffect(() => {
    onClick();
  }, []);

  const onClick = () => {
    // ios, mac
    if (isIos || isMac) {
      window.location.href =
        'https://apps.apple.com/us/app/%EB%85%B8%EB%B8%94%EB%A6%AC-%EC%83%9D%EB%A6%AC%EC%A3%BC%EA%B8%B0-%EC%83%9D%EB%A6%AC%EB%8B%AC%EB%A0%A5-%EA%B0%80%EC%9E%84%EA%B8%B0-%EB%B0%B0%EB%9E%80%EC%9D%BC/id6451451153';
    } else {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.nobly.app';
    }
  };

  return (
    <Container>
      <Text as='h1' fontSize='2rem' fontWeight={700} mb='2rem'>
        월경케어 본질에 집중합니다
      </Text>
      <Logo src='/logo.png' alt='nobly' />
      <Button
        onClick={onClick}
        mt='1.4rem'
        py='2rem'
        px='2.4rem'
        fontSize='1.2rem'
        fontWeight={700}
        color='white'
        bgColor='#ff5252'
        _hover={{ bgColor: 'rgba(255, 82, 82, 0.8)' }}
      >
        앱 다운로드 하기
      </Button>
    </Container>
  );
};

const Container = styled.main`
  min-height: calc(100vh - env(safe-area-inset-bottom));
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    min-height: -webkit-fill-available;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
`;

const Logo = styled.img`
  width: 75%;

  @media only screen and (min-width: 640px) {
    width: 60%;
  }

  @media only screen and (min-width: 1200px) {
    width: 45%;
  }

  @media only screen and (min-width: 1440px) {
    width: 35%;
  }
`;

// const Button = styled.button`
//   margin-top: 1.4rem;
//   padding: 1rem 1.4rem;
//   border: none;
//   border-radius: 8px;
//   background-color: #ff5252;
//   color: white;
//   font-size: 1.2rem;
// `;

export default AppDownload;
