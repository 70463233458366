import React from 'react';
import { VStack, Image, Text } from '@chakra-ui/react';
import HomeCardDivider from './HomeCardDivider';

interface CardProps {
  src: string;
  title: string;
  subTitle: string;
  content: string;
  footer: string;
}

const HomeGridCard = ({ src, title, subTitle, content, footer }: CardProps) => {
  return (
    <VStack
      justifyContent='space-between'
      alignItems='flex-start'
      w='100%'
      maxW='640px'
      h='480px'
      px='32px'
      py='48px'
      borderRadius='16px'
      bgColor='white'
      boxShadow='8px 8px 16px 0px rgba(0, 0, 0, 0.08)'
    >
      <Image src={src} w='96px' h='96px' />
      <VStack alignItems='flex-start' mb='24px'>
        <Text as='h4' fontSize='28px' fontWeight={700} lineHeight='36px' letterSpacing='-0.7px' color='#262E36'>
          {title}
        </Text>
        <Text
          fontSize='16px'
          fontWeight={500}
          lineHeight='24px'
          letterSpacing='-0.4px'
          color='#474C51'
          whiteSpace='pre-line'
        >
          {subTitle}
        </Text>
      </VStack>
      <Text fontSize='18px' lineHeight='26px' letterSpacing='0.45px' color='#474C51' wordBreak='keep-all'>
        {content}
      </Text>
      <HomeCardDivider />
      <Text fontSize='16px' fontWeight={500} lineHeight='24px' letterSpacing='-0.4px' color='#474C51'>
        {footer}
      </Text>
    </VStack>
  );
};

export default HomeGridCard;
